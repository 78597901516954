import React, { useState, useEffect } from "react";
import axios from "axios";
import infoIcon from "../../assets/images/svg/info-icon.svg";
import {globalConstants} from "../../services/globalVariables"
import PhoneInput from "./PhoneInput";


const BASEURL = globalConstants.BASE_URL;
const CompanyRegistrationDLC = ({ setStatus, companyId }) => {
    const [isCompanySectionVisible, setCompanySectionVisible] = useState(false);
    const [formData, setFormData] = useState({
        city: "",
        companyName: "",
        country: "US",
        dbaName: "",
        ein: "",
        email: "",
        entityType: "PRIVATE_PROFIT",
        phone: "",
        postalCode: "",
        state: "",
        street: "",
        vertical: "PROFESSIONAL",
        website: ""
    });


    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formStatus, setFormStatus] = useState("Incomplete");


    const statusStyles = {
        Incomplete: { color: "red" },
        Pending: { color: "orange" },
        Approved: { color: "green" },
        Default: { color: "red" },
    };

    const getStatusStyle = (status) => {
        return statusStyles[status] || statusStyles.Default;
    };

    useEffect(() => {
        const fetchCompanyData = async () => {
            if (companyId) {
                try {
                    const response = await axios.get(`${BASEURL}private/v1/10dlc/brand/${companyId}`);
                    if (response.status === 200) {
                        setFormData(response.data);
                        setFormStatus("Approved");
                    } else if (response.status === 204) {
                        setFormStatus("Incomplete");
                    }
                } catch (error) {
                    console.log("Error fetching company data");
                    setFormStatus("Incomplete");
                }
            }
        };

        fetchCompanyData();
    }, [companyId]);

    useEffect(() => {
        if (setStatus === "Pending") {
            const timer = setTimeout(() => {
                setStatus("Approved");
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [setStatus]);

    useEffect(() => {
        // Set company section visibility based on formStatus
        if (formStatus === "Incomplete") {
            setCompanySectionVisible(true);
        } else {
            setCompanySectionVisible(false); // Optionally hide when not incomplete
        }
    }, [formStatus]);

    const toggleCompanySection = () => {
        setCompanySectionVisible(!isCompanySectionVisible);
    };

    const handleInputChange = (e) => {
        if (isSubmitted) return;
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handlePhoneFromChild = (data) => {
        setFormData({
            ...formData,
            phone: data,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BASEURL}private/v1/10dlc/brand/${companyId}`, formData);
            if (response.status === 201) {
                setFormStatus("Pending");
                setStatus("Pending");
                setIsSubmitted(true);
                alert("Form submitted successfully!");
            }
        } catch (error) {
            console.log("Error submitting form");
        }
    };


    return (
        <div className="container p-4 pb-0 bg-white rounded-4">
            <div className="border-bottom pb-4">
                <div className="d-flex justify-content-between align-items-center pb-4">
                    <h2 className="fs-4 fw-normal font-weight-bold pb-0 mb-0">
                        Company <span style={getStatusStyle(formStatus)} className="fw-normal fs-6 d-inline-block">{formStatus}</span>
                    </h2>
                    <button
                        onClick={toggleCompanySection}
                        style={{
                            backgroundColor: isCompanySectionVisible ? "#0A0A0A14" : "#fff",
                            width: "64px",
                            height: "64px",
                            borderRadius: "12px",
                            padding: "4px",
                            border: "1px solid #fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {isCompanySectionVisible ? (
                            <div style={{ width: "21.33px", height: "2.67px", backgroundColor: "#666666" }}></div>
                        ) : (
                            <div style={{ position: "relative", width: "21.33px", height: "2.67px", backgroundColor: "#666666" }}>
                                <div style={{ position: "absolute", width: "21.33px", height: "2.67px", backgroundColor: "#666666", transform: "rotate(90deg)" }}></div>
                            </div>
                        )}
                    </button>
                </div>
                {formStatus === "Pending" && (
                    <div className="alert d-flex alert-warning border border-warning-subtle border-2 px-2 align-items-start" style={{ width: "555px" }} role="alert">
                        <img width="20" height="20" src={infoIcon} className="mx-2 my-1" alt="info Icon" decoding="async" />
                        <div>
                            <span>We will let you know once you are approved. This usually takes 5 minutes.</span>
                        </div>
                    </div>
                )}
            </div>

            {isCompanySectionVisible && (
                <form className="row mt-4" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Legal company
                                name*</label>
                            <input
                                type="text"
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Company
                                Type*
                            </label>
                            <select
                                name="entityType"
                                value="Private Company"
                                className={`form-select p-3 bg-light`}
                                required
                                readOnly
                                disabled={isSubmitted}
                                >
                            <option value="Private Company">Private Company</option>
                        </select>
                    </div>
                    <div className="mb-5">
                        <label className="form-label fw-medium font-size-sm mb-1 text-secondary">EIN*</label>
                        <input
                            type="text"
                                name="ein"
                                value={formData.ein}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Address/Street*</label>
                            <input
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">State*</label>
                            <select
                                name="state"
                                value={formData.state}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                            >
                                <option value="Mississippi">Mississippi</option>
                                <option value="CA">California</option>
                                <option value="NY">New York</option>
                                <option value="TX">Texas</option>
                            </select>
                        </div>
                        {/*<div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Stock Symbol</label>
                            <input
                                type="text"
                                name="stockSymbol"
                                value={formData.stockSymbol}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>*/}
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Industry*</label>
                            <select
                                name="vertical"
                                value={formData.vertical}
                                onChange={handleInputChange}
                                className={`form-select p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                disabled={isSubmitted}
                            >
                                <option value="PROFESSIONAL">Professional</option>
                                <option value="REAL_ESTATE">Real Estate</option>
                                <option value="HEALTHCARE">Healthcare</option>
                                <option value="HUMAN_RESOURCES">Human Resources</option>
                                <option value="ENERGY">Energy</option>
                                <option value="ENTERTAINMENT">Entertainment</option>
                                <option value="RETAIL">Retail</option>
                                <option value="TRANSPORTATION">Transportation</option>
                                <option value="AGRICULTURE">Agriculture</option>
                                <option value="INSURANCE">Insurance</option>
                                <option value="POSTAL">Postal</option>
                                <option value="EDUCATION">Education</option>
                                <option value="HOSPITALITY">Hospitality</option>
                                <option value="FINANCIAL">Financial</option>
                                <option value="POLITICAL">Political</option>
                                <option value="GAMBLING">Gambling</option>
                                <option value="LEGAL">Legal</option>
                                <option value="CONSTRUCTION">Construction</option>
                                <option value="NGO">Non-Profit Organization</option>
                                <option value="MANUFACTURING">Manufacturing</option>
                                <option value="GOVERNMENT">Government</option>
                                <option value="TECHNOLOGY">Technology</option>
                                <option value="COMMUNICATION">Communication</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Email*</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">DBA or brand name,
                                if different from legal name</label>
                            <input
                                type="text"
                                name="dbaName"
                                value={formData.dbaName}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Country of
                                registration</label>
                            <select
                                name="country"
                                value="US"
                                className={`form-select p-3 bg-light`}
                                readOnly
                                disabled={isSubmitted}
                            >
                                <option value="United States">United States</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">City*</label>
                            <input
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Zip code*</label>
                            <input
                                type="text"
                                name="postalCode"
                                value={formData.postalCode}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                required
                                readOnly={isSubmitted}
                            />
                        </div>
                        {/*<div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Stock Exchange</label>
                            <input
                                type="text"
                                name="stockExchange"
                                value={formData.stockExchange}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>*/}
                        <div className="mb-5">
                            <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Website/Online
                                Presence (optional)</label>
                            <input
                                type="text"
                                name="website"
                                value={formData.website}
                                onChange={handleInputChange}
                                className={`form-control p-3 ${isSubmitted ? 'bg-light' : ''}`}
                                readOnly={isSubmitted}
                            />
                        </div>
                        <PhoneInput onDataChange={handlePhoneFromChild} submitHandler={isSubmitted} />
                    </div>

                    <div className="col-12 d-flex justify-content-end mb-3">
                        <button
                            type="submit"
                            className={`btn ${isSubmitted ? "btn-secondary" : "btn-primary"}`}
                            disabled={isSubmitted}
                        >
                            Submit
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export default CompanyRegistrationDLC;
