import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import LoginCountriesModal from "../../components/modals/LoginCountriesModal";
import { ReactCountryFlag } from "react-country-flag";
import {LocalStorage} from "../../app/helpers";

const PhoneInput = ({ onDataChange, submitHandler }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({
        id: "1",
        country: "United States",
        destination: "+1",
        iso: "US",
    });
    const [phoneNumber, setPhoneNumber] = useState("");
    const phoneRegExp =
        /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/;

    const validationSchema = Yup.object({
        inputCodeCountry: Yup.string().required("Country code is required"),
        phoneInput: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .min(5, "Phone number must be at least 5 digits")
            .max(12, "Phone number must be exactly 12 digits")
            .required("Phone number is required"),
    });

    const formik = useFormik({
        initialValues: {
            inputCodeCountry: selectedCountry.destination,
            phoneInput: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("Form submitted with values:", values);
        },
    });

    const handleShowModal = (event) => {
        event.preventDefault(); // Prevent the default form submission
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handlePhoneChange = (e) => {
        const phoneInputValue = e.target.value;
         setPhoneNumber(phoneInputValue);
    };

    useEffect(() => {
        const fullPhoneNumber = `${selectedCountry.destination}${phoneNumber.replace(/\s/g, '')}`;
        onDataChange(fullPhoneNumber);
    }, [handlePhoneChange, handleShowModal]);


    const formatPhoneNumber = (value) => {
        if (value) {
            const numericValue = value.replace(/\D/g, "");
            const limitedValue = numericValue.slice(0, 10);
            return limitedValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
        }
        return "";
    };

    const handleSelectCountry = (country) => {
        setSelectedCountry(country);
        handleCloseModal();
    };

    return (
        <form>
            <div className="mb-5">
                <label className="form-label fw-medium font-size-sm mb-1 text-secondary">Phone Number*</label>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <button
                        className="login-button__country btn"
                        onClick={handleShowModal}
                        readOnly={submitHandler}
                    >
                        <ReactCountryFlag
                            countryCode={selectedCountry.iso}
                            svg
                            style={{
                                width: "24px",
                                height: "24px",
                                marginRight: "16px",
                                borderRadius: "20px",
                                objectFit: "cover",
                            }}
                            className={selectedCountry.iso}
                        />
                        <span>{selectedCountry.country}</span>
                    </button>
                    <div className="login-button__wrapper-phone">
                        <input
                            type="text"
                            className="login-button__index"
                            value={selectedCountry.destination}
                            name="inputCodeCountry"
                            readOnly
                        />
                        <div>
                            <input
                                type="text"
                                className={`login-button__phone ${
                                    formik.touched.phoneInput && formik.errors.phoneInput
                                        ? "input-error"
                                        : ""
                                }`}
                                placeholder="--- --- ----"
                                value={formatPhoneNumber(formik.values.phoneInput)}
                                onChange={(e) => {
                                    formik.setFieldValue("phoneInput", formatPhoneNumber(e.target.value));
                                    handlePhoneChange(e); }}
                                onBlur={formik.handleBlur}
                                name="phoneInput"
                                readOnly={submitHandler}
                            />
                            {formik.touched.phoneInput && formik.errors.phoneInput && (
                                <span className="input-error-text">
                                    {formik.errors.phoneInput}
                                </span>
                            )}
                        </div>
                    </div>
                    <LoginCountriesModal
                        show={showModal}
                        onHide={handleCloseModal}
                        onSelectCountry={handleSelectCountry}
                    />
                    </div>
            </div>
        </form>
    );
};

export default PhoneInput;
